.contact {
	position: relative;
	max-height: 90%;
	max-width: 95%;
	margin: 0 auto;
	line-height: 60px !important;

	h2 {
		color: hsl(0, 100%, 70%);
		font-size: calc(50px + 3vw) !important;
		font-weight: 500;
        margin-top: 70px !important;
        margin-bottom: 130px;
	}
	.text {
		display: inline-block;
		color: white;
		font-size: 1.8vmax;
		margin: 20px auto;
		font-weight: 500;
		animation: fadeIn 3s;
        margin-top: -50px !important;
	}
    .btn {
        text-align: center !important;
        background: 0 0;
        color: hsl(200, 0%, 90%);
        border: 1px solid hsl(200, 0%, 90%);
        line-height: normal;
        width: 80px;
        border-radius: 10px;
        font-size: 16px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 10px 14px 10px 14px;
        cursor: pointer;
        animation: fadeIn 1.5s;
        animation-fill-mode: forwards;
        opacity: 0;
        margin-top: 30px !important;
        box-sizing : content-box;
    }
    .btn:hover {
        color: grey;
        border-color: grey;
    }
}

.contact-form {
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 40px;
	}
	.flex {
		display: flex;
		flex-direction: column;
	}
	ul {
		padding: 0;
		margin: 0 auto;

		max-width: 60%;
		margin: 0 auto;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			margin-bottom: 0px;
			display: block;
			position: relative;
			opacity: 0;
			animation: fadeIn 2s;
			animation-fill-mode: forwards;
		}

		input[type='text'],
		input[type='email'],
		textarea {
			border: 0;
			background: hsl(200, 0%, 30%);
			height: 50px;
			font-size: 16px;
			color: hsl(200, 0%, 90%);
			margin: 10px auto;
			border-radius: 10px;
			width: 100%;
		}

		textarea {
			height: 100px;
			border-radius: 10px;
			width: 100%;
			margin-bottom: 0px;
		}
	}
}

@media screen and (max-width: 600px) {
    .contact {
        
            line-height: 30px !important;
        
        .text {
            font-size: 20px !important;
            margin-bottom: 40px !important;
            margin-top: 20px !important;
        }
        .links {
            margin: 40px auto !important;
        }
        .contact-form {
            .grid {
                display: flex;
                flex-direction: column;
            }
        }
        h2 {
            font-size: 60px !important;
            margin-bottom: 40px !important;
        }
    }

}
