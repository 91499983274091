.nav-bar {
  background: black;
  width: 100%;
  justify-content: center;
  z-index: 6;
  position: relative;

  .fixed {
    position: fixed;
  }
  .menu {
    margin-top: 0;
    top: 8%;
  }
  nav {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100px;
    gap: 2%;
    justify-content: center;
    z-index: 5;
    background-color: black;
    
    a, .burger  {
        padding: 0 1%;
        width: 10rem;
      font-size: 30px;
      color: white;
      line-height: 7rem;
      position: relative;
      display: block;
      text-decoration: none;
      text-align: center;
      
      &:hover, &:focus {
        color: grey;
        
        svg {
          opacity: 0.5;
          transition: all 0.25s ease-in-out;
        }
        &:after {
          opacity: 1;
          color: grey;
        }
      }

      &:after {
        content: '';
        margin: 0 3%;
        font-size: 10px;
        letter-spacing: 5px;
        line-height: 5rem;
        position: relative;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        bottom: 20px;
        height: 10px;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
      &.about-link {
        &:after {
          content: 'ABOUT';
        }
      }
      &.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }
      &.portfolio-link {
        &:after {
          content: 'PROJECTS';
        }
      }
      &.linkedin-link {
        &:after {
          content: 'LINKEDIN';
        }
      }
      &.github-link {
        &:after {
          content: 'GITHUB';
          margin-bottom: 20px;
        }
      }
      &.burger {
        &:after {
          content: '';
        }
      }

      &.active {
        svg {
          color: grey;
        }
      }
    }
  }
}

.menu-mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 200%;
    overflow: hidden !important;
    z-index: 3;
    margin: 0;
    top: 0;
}

@media screen and (max-width: 600px)
{
    .fixed {
        transform: translateY(-10px) !important;
    }
    nav {
        height: max-content !important;
    }
}