.about {
	position: relative;
	max-height: 90%;
	max-width: 95%;
	margin: 0 auto;

    .container {
        width: 80%;
        margin: auto;
        margin-top: 1%;
        animation: fadeIn 3s;
    }
    
    .text {
		display: inline-block;
		color: white;
		font-size: 1.75vmax;
		margin: 0 auto;
		font-weight: 500;
	}

	h1 {
		display: inline-block;
		color: white;
		font-size: 6vmax;
		margin: 0 auto;
		font-weight: 500;
	}

	h2 {
		font-size: calc(50px + 3vw) !important;
		margin: 10px auto !important;
		font-weight: 400;
		color: hsl(0, 100%, 70%);
	}
}
@media screen and (max-width: 600px)
{
    .about {
        .nav-bar {
            margin-bottom: 10px !important;
        }
        .text {
            margin-top: 50px !important;
            line-height: 30px !important;
            font-size: 24px !important;
            transform: translateY(-30px) !important;
        }
        h1, h2 {
            margin: 20px auto !important;
        }
        h2 {
            font-size: 70px !important;
        }
    }

}
