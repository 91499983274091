html {
    font-size: 60%;
}

body {
  margin: 0;
  color: white;
  text-align: center;
  font: 300 12px/1.5 'Coolvetica', 'Helvetica Neue';
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  position: relative;
}
::-webkit-scrollbar {
    width: 15px;
}
/* Track */
::-webkit-scrollbar-track {
    background: hsl(240, 0%, 15%);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(240, 0%, 20%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: hsl(240, 0%, 25%);
}

.circle-loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 85vh;
    width: 100vw;
}
.circle-loader {
	border: 16px solid white;
	border-top: 16px solid black;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 0.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.relative {
    position: relative !important;
    overflow: hidden !important;
}