.logo {
	display: block;
    opacity: 1;
    // animation: fadeIn 1s forwards;
    // animation-delay: 0s;

	img {
        margin-top: 60px !important;
		border-radius: 50px;
		display: block;
		margin: 0 auto;
		max-width: 450px;
		height: auto;
	}
}

.home-text-zone {
	position: relative;
	max-height: 90%;
	max-width: 95%;
	margin: 0 auto;

	.delay1 {
		opacity: 0;
		animation: fadeIn 4s forwards;
		animation-delay: 2s;
	}

	.delay2 {
		opacity: 0;
		animation: fadeIn 2s backwards;
		animation-fill-mode: forwards;
		animation-delay: 3s;
	}

	h1 {
		display: inline-block;
		color: white;
		margin: 10px auto;
		font-weight: 500;
        font-size: calc(2rem + 6vw) !important;
        margin-bottom: -60px !important;
	}
	.name {
		color: hsl(0, 90%, 50%);
	}

	.hi {
		opacity: 0;
		animation: fadeIn 4s forwards;      
        animation-delay: 0.5s; 
	}
	h1,
	h2 {
		font-family: 'Coolvetica';
	}
	h2 {
		font-size: 4vmax;		
		font-weight: 400;
		color: hsl(0, 100%, 70%);
        margin-top: 0px !important;
	}
}
@media screen and (max-width: 600px)
{
    .home-text-zone {
        h1 {
            font-size: 50px !important;
            line-height: 30px !important;
            margin: 0 auto !important;
            margin-top: 90px !important;
            margin-bottom: 28px !important;
        }
        h2 {
            margin: 0 auto !important;
            font-size: 40px !important;
            line-height: 50px !important;
            padding-bottom: 50px !important;
            margin-top: 60px !important;
        }
        .delay1, .delay2 {
            margin: -40px auto !important;
            font-size: 20px !important;
        }
        .nav-bar {
            margin-bottom: 30px !important;
        }
    }
    .logo {
        img {
            max-height: 150px;
        }
    }
}
