$primary-color: white;

@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

a,
p {
  color: $primary-color;
}

.loader-active {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 10%;
    height: 5%;
    animation: fadeOut 2s backwards;
    animation-fill-mode: forwards;
}

.loader-hidden {
    display: none;
}
.letter-spacing {
    letter-spacing: 4px;
}

@media (max-width: 768px) {
    iframe {
        max-width: 100vw;
        max-height: 50vh;
        margin-top: 33%;
    }
}